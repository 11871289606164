import React, { useState, useRef } from "react";
import { Colors, Constants, Fonts, Images, Values } from "../../../../config";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native-web";
import { useTranslation } from "react-i18next";
import { CImage, DotSplitedText } from "../../../../components/atoms";
import { AlertModal } from "../../../../components/molecules";
import dynamic from "next/dynamic";
import {
  formatCurrency,
  formatNumber,
  getClass,
  nthNumber,
} from "../../../../helpers/formatHelpers";
import Link from "next/link";
import { generateURLSlug } from "../../../../../helpers";
import { uniqBy } from "lodash";

const CAvatar = dynamic(() => import("../../../../components/atoms/CAvatar"), {
  ssr: false,
});

const VenueInfoView = (props) => {
  const { t } = useTranslation();
  const { category, isMobileVersion } = props;
  const alertModalGenreRef = useRef(null);
  const alertModalFeatureRef = useRef(null);
  const alertModalCategoryRef = useRef(null);
  const alertModalLocationRef = useRef(null);

  const [showPopupOfficial, setShowPopupOfficial] = useState(false);

  const {
    id,
    isFollow,
    profileId,
    isVirtual,
    isInPerson,
    title,
    location,
    startDate,
    periodString,
    totalFollower,
    totalFollowing,
    totalConnection,
    logo,
    nickname,
    username,
    rating,
    userType,
    activity,
    bio,
    city,
    nationality,
    genres,
    isOfficial,
    isPro,
    tag_venue_2,
  } = props.item;
  let genreItems = [];
  if (genres) {
    genreItems = genres;
  }
  let userTypeName = "-";
  let rooftopFloor = "-";
  let rooftopView = "-";
  const tagChildren = props.item?.tags?.find((i) =>
    i?.children?.find(
      (j) =>
        j.category === Constants.tagCategory.TAG_CATEGORY_VENUE ||
        j.category === Constants.tagCategory.TAG_CATEGORY_FLOOR ||
        j.category === Constants.tagCategory.TAG_CATEGORY_VIEW
    )
  )?.children;
  for (let i = 0; i < tagChildren?.length; i++) {
    if (tagChildren[i]?.category === Constants.tagCategory.TAG_CATEGORY_VENUE) {
      userTypeName = tagChildren[i]?.children[0]?.name;
    }
    if (tagChildren[i]?.category === Constants.tagCategory.TAG_CATEGORY_FLOOR) {
      rooftopFloor = tagChildren[i]?.children[0]?.maxValue;
    }
    if (tagChildren[i]?.category === Constants.tagCategory.TAG_CATEGORY_VIEW) {
      rooftopView = tagChildren[i]?.children[0]?.name;
    }
  }
  const genreName = genreItems.length > 0 ? genreItems[0].title : null;
  const moreGenre =
    genreItems.length > 1 ? "+" + (genreItems.length - 1) : null;
  const fullGenreNames = genreItems
    .map((genreItem) => genreItem.title)
    .join(", ");

  const prefabHomeCategory = () => {
    const prefabHomeCategories = [];
    let resultTags = [];
    props.item?.tags.forEach((item, index) => {
      const tagGroup = props.item?.tags[index];
      const _tagCategories =
        tagGroup?.children?.filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_FLOOR &&
            i.category !== Constants.tagCategory.TAG_CATEGORY_VIEW
        ) || [];

      resultTags = resultTags.concat(_tagCategories);
    });
    resultTags
      .filter(
        (i) => i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_CATEGORY
      )
      .map((item) => {
        prefabHomeCategories.push(item.children[0]);
      });
    return uniqBy(prefabHomeCategories.map((i) => i.title));
  };

  const venueStatus =
    props.item?.venueStatus === Constants.venueStatus.FE_STAT_OPEN
      ? "Open"
      : props.item?.venueStatus === Constants.venueStatus.FE_STAT_CLOSE
      ? "Closed"
      : props.item?.venueStatus ===
        Constants.venueStatus.FE_STAT_PERMANENTLY_CLOSED
      ? "Permanently Closed"
      : "Temporarily Closed";
  const venueTypeTexts =
    category === Constants.categoryType.CATEGORY_VENUE
      ? [userTypeName, venueStatus]
      : category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
        category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
        category === Constants.categoryType.CATEGORY_ANIMAL ||
        category === Constants.categoryType.CATEGORY_CASTLE
      ? []
      : [venueStatus];

  const hasNationality = nationality != null && nationality != "";
  const profileStatusItems = [
    {
      value: formatNumber(totalFollower || 0),
      label: t("key_profile_status_followers"),
      type: Constants.profileStatusType.PROFILE_STATUS_FOLLOWER,
    },
    {
      value: formatNumber(totalFollowing || 0),
      label: t("key_profile_status_followings"),
      type: Constants.profileStatusType.PROFILE_STATUS_FOLLOWING,
    },
    {
      value: formatNumber(totalConnection || 0),
      label: t("key_profile_status_connections"),
      type: Constants.profileStatusType.PROFILE_STATUS_CONNECTION,
    },
  ];

  const getPrefabTagCategory = () => {
    let result = [];
    props.item?.tags.forEach((item, index) => {
      const tagGroup = props.item?.tags[index];
      const _tagCategories =
        tagGroup?.children?.filter(
          (i) =>
            i.category !== Constants.tagCategory.TAG_CATEGORY_FLOOR &&
            i.category !== Constants.tagCategory.TAG_CATEGORY_VIEW
        ) || [];

      result = result.concat(_tagCategories);
    });
    return concatChildrenById(
      result.filter(
        (i) => i.category === Constants.tagCategory.TAG_CATEGORY_PREFAB_CATEGORY
      )
    ).map((i) => ({
      ...i,
      children: uniqBy(i.children, "id"),
    }))[0];
  };

  // Function to concatenate children by id
  function concatChildrenById(arr) {
    const result = [];
    const idMap = {};

    arr.forEach((item) => {
      const id = item.id;

      if (!idMap[id]) {
        // Initialize a copy of the item with an empty children array
        const copy = { ...item };
        copy.children = [];
        result.push(copy);
        idMap[id] = copy;
      }

      // Concatenate the children
      idMap[id].children = idMap[id].children.concat(item.children);
    });

    return result;
  }

  return (
    <View style={[styles.container, props.style]}>
      <View
        style={
          isMobileVersion
            ? {}
            : {
                alignSelf: "center",
                maxWidth: Constants.PROFILE_PAGE_WIDTH,
                width: "100%",
              }
        }>
        {isMobileVersion ? (
          <View>
            <View style={styles.userInfoContainer}>
              {category !== Constants.categoryType.CATEGORY_RESORT &&
                category !== Constants.categoryType.CATEGORY_APARTHOTEL && (
                  <CAvatar
                    url={
                      props?.item?.company?.logo ||
                      logo ||
                      (category === Constants.categoryType.CATEGORY_ROOFTOP ||
                      category === Constants.categoryType.CATEGORY_SPA ||
                      category ===
                        Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
                      category ===
                        Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
                      category ===
                        Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
                      category ===
                        Constants.categoryType.CATEGORY_PREFAB_HOMES ||
                      category === Constants.categoryType.CATEGORY_ZOO ||
                      category === Constants.categoryType.CATEGORY_ANIMAL ||
                      category === Constants.categoryType.CATEGORY_PARKFUL ||
                      category === Constants.categoryType.CATEGORY_CASINO ||
                      category === Constants.categoryType.CATEGORY_CASTLE ||
                      category === Constants.categoryType.CATEGORY_RESORT ||
                      category === Constants.categoryType.CATEGORY_APARTHOTEL
                        ? "Local:defaultGeneral"
                        : "Local:defaultVenue")
                    }
                    size={70}
                    alt={`${nickname} ${
                      category === Constants.categoryType.CATEGORY_ARTIST
                        ? "Artist"
                        : category === Constants.categoryType.CATEGORY_VENUE
                        ? "Venue"
                        : category === Constants.categoryType.CATEGORY_ROOFTOP
                        ? "Rooftop"
                        : category === Constants.categoryType.CATEGORY_SPA
                        ? "Spa"
                        : category ===
                          Constants.categoryType.CATEGORY_VENUE_BEACHFUL
                        ? "Beach Club"
                        : category ===
                          Constants.categoryType.CATEGORY_PREFAB_COMPANY
                        ? "Company"
                        : category ===
                          Constants.categoryType.CATEGORY_PREFAB_HOMES
                        ? "Home"
                        : category === Constants.categoryType.CATEGORY_ZOO
                        ? "Zoo/animal park"
                        : category === Constants.categoryType.CATEGORY_ANIMAL
                        ? "Animal"
                        : category === Constants.categoryType.CATEGORY_PARKFUL
                        ? "Park"
                        : category === Constants.categoryType.CATEGORY_CASINO
                        ? "Casino"
                        : category === Constants.categoryType.CATEGORY_CASTLE
                        ? "Castle"
                        : category === Constants.categoryType.CATEGORY_RESORT
                        ? "Resort"
                        : category ===
                          Constants.categoryType.CATEGORY_APARTHOTEL
                        ? "Aparthotel"
                        : category ===
                          Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                        ? "Bar"
                        : category === Constants.categoryType.CATEGORY_EVENT
                        ? "Event"
                        : "Festival"
                    } Logo`}
                  />
                )}
              <View
                style={[
                  styles.nameContainer,
                  isMobileVersion
                    ? { alignItems: "flex-start", marginLeft: 16 }
                    : {},
                ]}>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 4,
                    paddingRight: 40,
                  }}>
                  <Text
                    style={[
                      styles.nickname,
                      isMobileVersion
                        ? {
                            fontSize: 16,
                            lineHeight: 19.5,
                            textAlign: "left",
                          }
                        : {},
                    ]}>
                    <h1>{nickname}</h1>
                  </Text>
                  {(isOfficial || isPro) && (
                    <View
                      onMouseOver={() => setShowPopupOfficial(true)}
                      onMouseLeave={() => setShowPopupOfficial(false)}
                      style={{
                        marginLeft: 6,
                        marginTop: -2,
                        position: "relative",
                      }}>
                      <CImage
                        source={Images.iconVerifiedBadge}
                        alt="badge official"
                        width={14}
                        height={14}
                        layout="fixed"
                      />
                      {showPopupOfficial && (
                        <View
                          style={{
                            position: "absolute",
                            width: isMobileVersion ? 260 : 336,
                            height: isMobileVersion ? 140 + 24 : 182 + 24,
                            zIndex: 999,
                            left: -(Values.deviceWidth / 2),
                            top: 0,
                            paddingTop: 24,
                          }}>
                          <View
                            style={{
                              backgroundColor: getClass("bg-gray1"),
                              width: isMobileVersion ? 260 : 336,
                              height: isMobileVersion ? 140 : 182,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingHorizontal: 22,
                              borderRadius: 18,
                              width: "100%",
                              height: "100%",
                              boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                            }}>
                            <CImage
                              source={Images.iconVerifiedBadgeMedium}
                              alt="verified account"
                              width={isMobileVersion ? 24 : 35}
                              height={isMobileVersion ? 24 : 35}
                              layout="fixed"
                            />
                            <Text
                              style={{
                                fontFamily: Fonts.primaryBold,
                                fontSize: isMobileVersion ? 16 : 20,
                                lineHeight: isMobileVersion ? 20 : 24,
                                color: Colors.white,
                                marginVertical: isMobileVersion ? 9 : 14,
                                textAlign: "center",
                              }}>
                              Official Account
                            </Text>
                            <Text
                              style={{
                                fontFamily: Fonts.primaryRegular,
                                fontSize: isMobileVersion ? 12 : 16,
                                lineHeight: isMobileVersion ? 16 : 18,
                                color: Colors.white80,
                                textAlign: "center",
                              }}>
                              This is an official account verified by{" "}
                              {process.env.NEXT_PUBLIC_APP_NAME}
                            </Text>
                          </View>
                        </View>
                      )}
                    </View>
                  )}

                  {/* <BarRatingView
                    rating={rating}
                    style={{ marginLeft: 6, width: 17, height: 17, zIndex: 0 }}
                    maxHeight={14.41}
                    barStyle={{
                      marginLeft: 1.64,
                      width: 1.75,
                      borderRadius: 0.8,
                    }}
                  /> */}
                </View>

                {category === Constants.categoryType.CATEGORY_PREFAB_HOMES ? (
                  <Link
                    href={generateURLSlug(
                      props?.item?.company?.id,
                      props?.item?.company?.nickname,
                      null,
                      Constants.categoryType.CATEGORY_PREFAB_COMPANY,
                      "about"
                    )}>
                    <a>
                      <Text
                        style={[
                          styles.username,
                          { marginTop: 4, textDecorationLine: "underline" },
                          isMobileVersion
                            ? {
                                fontSize: 10,
                                lineHeight: 18,
                              }
                            : {},
                        ]}>
                        {props.item?.company?.nickname}
                      </Text>
                    </a>
                  </Link>
                ) : (
                  <Text
                    style={[
                      styles.username,
                      { marginTop: 4 },
                      isMobileVersion
                        ? {
                            fontSize: 10,
                            lineHeight: 18,
                          }
                        : {},
                    ]}>
                    @{username}
                  </Text>
                )}
                <View
                  style={[
                    styles.rowContainer,
                    { alignItems: "center", marginTop: 4 },
                  ]}>
                  {category === Constants.categoryType.CATEGORY_VENUE && (
                    <View style={{ width: 18, height: 18, marginRight: 2 }}>
                      <CImage
                        source={Images.iconSearchVenue}
                        style={styles.icon}
                        height={14}
                        width={14}
                        layout="fixed"
                        resizeMode="contain"
                      />
                    </View>
                  )}
                  <DotSplitedText
                    texts={venueTypeTexts}
                    textStyle={{
                      color: getClass("text-white"),
                      lineHeight: 20,
                      fontSize: 12,
                      fontFamily: Fonts.primaryRegular,
                    }}
                    dotStyle={{ backgroundColor: Colors.white }}
                    style={{
                      marginLeft:
                        category === Constants.categoryType.CATEGORY_VENUE
                          ? 4
                          : 0,
                    }}
                    isSecondOpacity={true}
                  />
                </View>
              </View>
            </View>
            <Text style={styles.bioMobile}>{bio?.substring(0, 30)}</Text>
            <View
              style={[
                styles.rowContainer,
                {
                  marginTop: 10,
                  marginHorizontal: 16,
                  justifyContent: "space-between",
                },
              ]}>
              {category === Constants.categoryType.CATEGORY_VENUE && (
                <View style={styles.rowContainer}>
                  <View style={{ width: 14, height: 14, marginRight: 4 }}>
                    <CImage
                      source={Images.iconTagMusic}
                      width={14}
                      height={14}
                    />
                  </View>
                  <Text
                    style={[
                      styles.text,
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {genreName}
                  </Text>
                  {moreGenre && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 22,
                        borderRadius: 11,
                        marginLeft: 4,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalGenreRef.current.alert(
                          "Music",
                          fullGenreNames
                        );
                      }}>
                      <Text
                        style={[
                          styles.boldText,
                          isMobileVersion
                            ? {
                                fontSize: 10,
                                lineHeight: 12,
                              }
                            : {},
                        ]}>
                        {moreGenre}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_ROOFTOP && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagView}
                    style={[styles.locationPin]}
                  />{" "}
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {`${
                      rooftopFloor === "-"
                        ? "-"
                        : nthNumber(rooftopFloor?.toString()?.split(".")[0]) +
                          " Floor"
                    }  |  ${rooftopView === "-" ? "-" : rooftopView + "°"}`}
                  </Text>
                </View>
              )}
              {(category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
                category === Constants.categoryType.CATEGORY_SPA ||
                category ===
                  Constants.categoryType.CATEGORY_VENUE_BEACHFUL) && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagGeneral}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {tag_venue_2}
                  </Text>
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_PREFAB_HOMES && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ")}
                  </Text>
                  {props.item.tag_category?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Category",
                          props.item.tag_category?.join(", ")
                        );
                      }}>
                      <Text
                        style={[
                          styles.boldText,
                          isMobileVersion
                            ? {
                                fontSize: 10,
                                lineHeight: 12,
                              }
                            : {},
                        ]}>
                        {"+" + (props.item.tag_category?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_ZOO && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagStatus}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ")}
                  </Text>
                  {props.item.tag_category?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Category",
                          props.item.tag_category?.join(", ")
                        );
                      }}>
                      <Text
                        style={[
                          styles.boldText,
                          isMobileVersion
                            ? {
                                fontSize: 10,
                                lineHeight: 12,
                              }
                            : {},
                        ]}>
                        {"+" + (props.item.tag_category?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_PREFAB_COMPANY && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {getPrefabTagCategory()
                      ?.children?.filter((i, index) => index <= 1)
                      ?.map((i) => i.name)
                      ?.join(", ")}
                  </Text>
                  {getPrefabTagCategory()?.children?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Category",
                          getPrefabTagCategory()
                            ?.children?.map((i) => i.name)
                            ?.join(", ")
                        );
                      }}>
                      <Text
                        style={[
                          styles.boldText,
                          isMobileVersion
                            ? {
                                fontSize: 10,
                                lineHeight: 12,
                              }
                            : {},
                        ]}>
                        {"+" + (getPrefabTagCategory()?.children?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}

              {category === Constants.categoryType.CATEGORY_ANIMAL && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ")}
                  </Text>
                  {props.item.tag_category?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Features",
                          props.item.tag_category?.join(", ")
                        );
                      }}>
                      <Text
                        style={[
                          styles.boldText,
                          isMobileVersion
                            ? {
                                fontSize: 10,
                                lineHeight: 12,
                              }
                            : {},
                        ]}>
                        {"+" + (props.item.tag_category?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_PARKFUL && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ")}
                  </Text>
                  {props.item.tag_category?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Category",
                          props.item.tag_category?.join(", ")
                        );
                      }}>
                      <Text
                        style={[
                          styles.boldText,
                          isMobileVersion
                            ? {
                                fontSize: 10,
                                lineHeight: 12,
                              }
                            : {},
                        ]}>
                        {"+" + (props.item.tag_category?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_CASINO && (
                <TouchableOpacity
                  style={[styles.rowContainer]}
                  onPress={() => {
                    alertModalCategoryRef.current.alert(
                      "Category",
                      props.item.tag_category
                    );
                  }}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagEventType}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category}
                  </Text>
                </TouchableOpacity>
              )}
              {category === Constants.categoryType.CATEGORY_CASTLE && (
                <View style={styles.rowContainer}>
                  <Text
                    style={[
                      styles.text,
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    Built in {props.item.founding} {props.item.founding_unit}
                  </Text>
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_RESORT && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagArea}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_area}
                  </Text>
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_APARTHOTEL && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={14}
                    height={14}
                    layout="fixed"
                    source={Images.iconTagEventType}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category}
                  </Text>
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_PREFAB_HOMES ? (
                <View style={[styles.rowContainer]}>
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item?.tag_price
                      ? `From ${formatCurrency(
                          formatNumber(props.item?.tag_price)
                        )}`
                      : ""}
                    {props.item?.tag_price ? " | " : ""}
                    {`Min ${formatNumber(props.item?.tag_size)} sqft`}
                  </Text>
                </View>
              ) : (
                <>
                  {category ===
                  Constants.categoryType.CATEGORY_ANIMAL ? null : (
                    <TouchableOpacity
                      style={styles.rowContainer}
                      onPress={() =>
                        nationality !== "" && nationality !== null
                          ? alertModalLocationRef.current.alert(
                              "Location",
                              // `${city}, ${nationality}`
                              category ===
                                Constants.categoryType.CATEGORY_PREFAB_COMPANY
                                ? `${nationality}`
                                : `${city}`
                            )
                          : null
                      }>
                      <View style={{ width: 14, height: 14, marginRight: 4 }}>
                        <CImage
                          source={Images.iconLocationPin}
                          width={12}
                          height={12}
                        />
                      </View>
                      <Text
                        style={[
                          styles.text,
                          isMobileVersion
                            ? {
                                fontSize: 12,
                                lineHeight: 20,
                              }
                            : {},
                        ]}>
                        <address style={{ fontStyle: "normal" }}>
                          {/* {`${city}, ${nationality}`} */}
                          {category ===
                          Constants.categoryType.CATEGORY_PREFAB_COMPANY
                            ? `${nationality}`
                            : `${city}`}
                        </address>
                      </Text>
                    </TouchableOpacity>
                  )}
                </>
              )}
            </View>
          </View>
        ) : (
          <View>
            {category !== Constants.categoryType.CATEGORY_RESORT &&
              category !== Constants.categoryType.CATEGORY_APARTHOTEL && (
                <CAvatar
                  url={
                    props?.item?.company?.logo ||
                    logo ||
                    (category === Constants.categoryType.CATEGORY_ROOFTOP ||
                    category === Constants.categoryType.CATEGORY_SPA ||
                    category ===
                      Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
                    category ===
                      Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
                    category ===
                      Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
                    category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
                    category === Constants.categoryType.CATEGORY_ZOO ||
                    category === Constants.categoryType.CATEGORY_ANIMAL ||
                    category === Constants.categoryType.CATEGORY_PARKFUL ||
                    category === Constants.categoryType.CATEGORY_CASINO ||
                    category === Constants.categoryType.CATEGORY_CASTLE ||
                    category === Constants.categoryType.CATEGORY_RESORT ||
                    category === Constants.categoryType.CATEGORY_APARTHOTEL
                      ? "Local:defaultGeneral"
                      : "Local:defaultVenue")
                  }
                  size={130}
                  style={{ alignSelf: "center", marginTop: -65 }}
                  alt={`${nickname} ${
                    category === Constants.categoryType.CATEGORY_ARTIST
                      ? "Artist"
                      : category === Constants.categoryType.CATEGORY_VENUE
                      ? "Venue"
                      : category === Constants.categoryType.CATEGORY_ROOFTOP
                      ? "Rooftop"
                      : category === Constants.categoryType.CATEGORY_SPA
                      ? "Spa"
                      : category ===
                        Constants.categoryType.CATEGORY_VENUE_BEACHFUL
                      ? "Beach Club"
                      : category ===
                        Constants.categoryType.CATEGORY_PREFAB_COMPANY
                      ? "Company"
                      : category ===
                        Constants.categoryType.CATEGORY_PREFAB_HOMES
                      ? "Home"
                      : category === Constants.categoryType.CATEGORY_ZOO
                      ? "Zoo/animal park"
                      : category === Constants.categoryType.CATEGORY_ANIMAL
                      ? "Animal"
                      : category === Constants.categoryType.CATEGORY_PARKFUL
                      ? "Park"
                      : category === Constants.categoryType.CATEGORY_CASINO
                      ? "Casino"
                      : category ===
                        Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                      ? "Bar"
                      : category === Constants.categoryType.CATEGORY_EVENT
                      ? "Event"
                      : "Festival"
                  } Logo`}
                />
              )}
            <View style={{ alignSelf: "center" }}>
              <View style={styles.nameContainer}>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 25,
                    alignItems: "center",
                  }}>
                  <Text style={styles.nickname}>
                    <h1>{nickname}</h1>
                  </Text>
                  {(isOfficial || isPro) && (
                    <View
                      onMouseOver={() => setShowPopupOfficial(true)}
                      onMouseLeave={() => setShowPopupOfficial(false)}
                      style={{
                        marginLeft: 8,
                        marginTop: -16,
                        position: "relative",
                      }}>
                      <CImage
                        source={Images.iconVerifiedBadge}
                        alt="badge official"
                        width={14}
                        height={14}
                        layout="fixed"
                      />
                      {showPopupOfficial && (
                        <View
                          style={{
                            position: "absolute",
                            width: isMobileVersion ? 260 + 24 : 336 + 24,
                            height: isMobileVersion ? 140 : 182,
                            zIndex: 10,
                            left: 0,
                            top: -91,
                            paddingLeft: 24,
                          }}>
                          <View
                            style={{
                              backgroundColor: getClass("bg-gray1"),
                              width: isMobileVersion ? 260 : 336,
                              height: isMobileVersion ? 140 : 182,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingHorizontal: 22,
                              borderRadius: 18,
                              width: "100%",
                              height: "100%",
                              boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                            }}>
                            <CImage
                              source={Images.iconVerifiedBadgeMedium}
                              alt="verified account"
                              width={isMobileVersion ? 24 : 35}
                              height={isMobileVersion ? 24 : 35}
                              layout="fixed"
                            />
                            <Text
                              style={{
                                fontFamily: Fonts.primaryBold,
                                fontSize: isMobileVersion ? 16 : 20,
                                lineHeight: isMobileVersion ? 20 : 24,
                                color: Colors.white,
                                marginVertical: isMobileVersion ? 9 : 14,
                                textAlign: "center",
                              }}>
                              Official Account
                            </Text>
                            <Text
                              style={{
                                fontFamily: Fonts.primaryRegular,
                                fontSize: isMobileVersion ? 12 : 16,
                                lineHeight: isMobileVersion ? 16 : 18,
                                color: Colors.white80,
                                textAlign: "center",
                              }}>
                              This is an official account verified by{" "}
                              {process.env.NEXT_PUBLIC_APP_NAME}
                            </Text>
                          </View>
                        </View>
                      )}
                    </View>
                  )}

                  {/* <BarRatingView
                    rating={rating}
                    style={{
                      width: 20,
                      height: 20,
                      position: "absoute",
                      right: -12,
                      top: 0,
                      zIndex: 0,
                    }}
                    maxHeight={20}
                    barStyle={{
                      marginLeft: 1.07,
                      width: 3.21,
                      borderRadius: 3.1,
                    }}
                  /> */}
                </View>

                {category === Constants.categoryType.CATEGORY_PREFAB_HOMES ? (
                  <Link
                    href={generateURLSlug(
                      props?.item?.company?.id,
                      props?.item?.company?.nickname,
                      null,
                      Constants.categoryType.CATEGORY_PREFAB_COMPANY,
                      "about"
                    )}>
                    <a>
                      <Text
                        style={[
                          styles.username,
                          { marginTop: 13, textDecorationLine: "underline" },
                        ]}>
                        {props.item?.company?.nickname}
                      </Text>
                    </a>
                  </Link>
                ) : (
                  <Text style={[styles.username, { marginTop: 13 }]}>
                    @{username}
                  </Text>
                )}
                <View
                  style={[
                    styles.rowContainer,
                    { alignItems: "center", marginTop: 15 },
                  ]}>
                  {category === Constants.categoryType.CATEGORY_VENUE && (
                    <CImage
                      source={Images.iconSearchVenue}
                      style={styles.icon}
                      height={20}
                      width={20}
                      layout="fixed"
                      resizeMode="contain"
                    />
                  )}
                  <DotSplitedText
                    texts={venueTypeTexts}
                    textStyle={{
                      color: getClass("text-white"),
                      lineHeight: 20,
                      fontSize: 16,
                      fontFamily: Fonts.primaryRegular,
                    }}
                    dotStyle={{
                      backgroundColor: Colors.white,
                      width: 3,
                      height: 3,
                      borderRadius: 3,
                    }}
                    style={{
                      marginLeft:
                        category === Constants.categoryType.CATEGORY_VENUE
                          ? 10
                          : 0,
                    }}
                    isSecondOpacity={true}
                  />
                </View>
              </View>
            </View>

            <View
              style={[
                styles.rowContainer,
                {
                  marginTop: 34,
                  marginHorizontal: 16,
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                },
              ]}>
              {category === Constants.categoryType.CATEGORY_VENUE && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagMusic}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {genreName}
                  </Text>
                  {moreGenre && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalGenreRef.current.alert(
                          "Music",
                          fullGenreNames
                        );
                      }}>
                      <Text style={styles.boldText}>{moreGenre}</Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_ROOFTOP && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagView}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {`${
                      rooftopFloor === "-"
                        ? "-"
                        : nthNumber(rooftopFloor?.toString()?.split(".")[0]) +
                          " Floor"
                    }  |  ${rooftopView === "-" ? "-" : rooftopView + "°"}`}
                  </Text>
                </View>
              )}
              {(category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
                category === Constants.categoryType.CATEGORY_SPA ||
                category ===
                  Constants.categoryType.CATEGORY_VENUE_BEACHFUL) && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagGeneral}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {tag_venue_2}
                  </Text>
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_PREFAB_HOMES && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {prefabHomeCategory()
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ")}
                  </Text>
                  {prefabHomeCategory()?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Category",
                          prefabHomeCategory()?.join(", ")
                        );
                      }}>
                      <Text style={styles.boldText}>
                        {"+" + (prefabHomeCategory()?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_ZOO && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ")}
                  </Text>
                  {props.item.tag_category?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Category",
                          props.item.tag_category?.join(", ")
                        );
                      }}>
                      <Text style={styles.boldText}>
                        {"+" + (props.item.tag_category?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_PREFAB_COMPANY && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {getPrefabTagCategory()
                      ?.children?.filter((i, index) => index <= 1)
                      ?.map((i) => i.name)
                      ?.join(", ")}
                  </Text>
                  {getPrefabTagCategory()?.children?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Category",
                          getPrefabTagCategory()
                            ?.children?.map((i) => i.name)
                            ?.join(", ")
                        );
                      }}>
                      <Text style={styles.boldText}>
                        {"+" + (getPrefabTagCategory()?.children?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_ANIMAL && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ")}
                  </Text>
                  {props.item.tag_category?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Features",
                          props.item.tag_category?.join(", ")
                        );
                      }}>
                      <Text style={styles.boldText}>
                        {"+" + (props.item.tag_category?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_PARKFUL && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagFeature}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category
                      ?.filter((i, index) => index <= 1)
                      ?.join(", ")}
                  </Text>
                  {props.item.tag_category?.length > 2 && (
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingHorizontal: 8,
                        borderWidth: 1,
                        height: 25,
                        borderRadius: 25,
                        marginLeft: 8,
                        borderColor: Colors.spliterLine,
                      }}
                      onPress={() => {
                        alertModalFeatureRef.current.alert(
                          "Category",
                          props.item.tag_category?.join(", ")
                        );
                      }}>
                      <Text style={styles.boldText}>
                        {"+" + (props.item.tag_category?.length - 2)}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_CASINO && (
                <TouchableOpacity
                  style={[styles.rowContainer]}
                  onPress={() => {
                    alertModalCategoryRef.current.alert(
                      "Category",
                      props.item.tag_category
                    );
                  }}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagEventType}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category}
                  </Text>
                </TouchableOpacity>
              )}
              {category === Constants.categoryType.CATEGORY_CASTLE && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconCalendarWhite}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    Built in {props.item.founding} {props.item.founding_unit}
                  </Text>
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_RESORT && (
                <View
                  style={[styles.rowContainer]}
                  onPress={() => {
                    alertModalCategoryRef.current.alert(
                      "Category",
                      props.item.tag_category
                    );
                  }}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagArea}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_area}
                  </Text>
                </View>
              )}
              {category === Constants.categoryType.CATEGORY_APARTHOTEL && (
                <View style={[styles.rowContainer]}>
                  <CImage
                    width={20}
                    height={20}
                    layout="fixed"
                    source={Images.iconTagEventType}
                    style={[styles.locationPin]}
                  />
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item.tag_category}
                  </Text>
                </View>
              )}
              <Text style={styles.bio}>{bio?.substring(0, 30)}</Text>

              {category === Constants.categoryType.CATEGORY_PREFAB_HOMES ? (
                <View style={[styles.rowContainer]}>
                  <Text
                    style={[
                      styles.text,
                      { marginLeft: 8 },
                      isMobileVersion
                        ? {
                            fontSize: 12,
                            lineHeight: 20,
                          }
                        : {},
                    ]}>
                    {props.item?.tag_price
                      ? `From ${formatCurrency(
                          formatNumber(props.item?.tag_price)
                        )}`
                      : ""}
                    {props.item?.tag_price ? " | " : ""}
                    {`Min ${formatNumber(props.item?.tag_size)} sqft`}
                  </Text>
                </View>
              ) : (
                <>
                  {category ===
                  Constants.categoryType.CATEGORY_ANIMAL ? null : (
                    <TouchableOpacity
                      style={[styles.rowContainer]}
                      disabled={!hasNationality}
                      onPress={() => {
                        if (hasNationality)
                          alertModalLocationRef.current.alert(
                            "Location",
                            category ===
                              Constants.categoryType.CATEGORY_PREFAB_COMPANY
                              ? `${nationality}`
                              : `${city}`
                            // `${city}, ${nationality}`
                          );
                      }}>
                      <CImage
                        width={20}
                        height={20}
                        layout="fixed"
                        source={Images.iconLocationPin}
                        style={styles.locationPin}
                      />
                      <Text
                        style={[
                          styles.text,
                          { marginLeft: 5 },
                          isMobileVersion
                            ? {
                                fontSize: 12,
                                lineHeight: 20,
                              }
                            : {},
                        ]}>
                        <address style={{ fontStyle: "normal" }}>
                          {category ===
                          Constants.categoryType.CATEGORY_PREFAB_COMPANY
                            ? `${nationality}`
                            : `${city}`}
                          {/* {`${city}, ${nationality}`} */}
                        </address>
                      </Text>
                    </TouchableOpacity>
                  )}
                </>
              )}
            </View>
          </View>
        )}

        <AlertModal
          icon={Images.iconTagMusic}
          ref={alertModalGenreRef}
          isMobileVersion={isMobileVersion}
          isSmall={true}
        />
        <AlertModal
          icon={Images.iconTagFeature}
          ref={alertModalFeatureRef}
          isMobileVersion={isMobileVersion}
          isSmall={true}
        />
        <AlertModal
          icon={Images.iconTagEventType}
          ref={alertModalCategoryRef}
          isMobileVersion={isMobileVersion}
          isSmall={true}
        />
        <AlertModal
          icon={Images.iconLocationPin}
          ref={alertModalLocationRef}
          isMobileVersion={isMobileVersion}
          isSmall={true}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    backgroundColor: getClass("background"),
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  userInfoContainer: {
    alignSelf: "stretch",
    flexDirection: "row",
    marginHorizontal: 16,
    marginTop: 15,
  },
  nickname: {
    fontSize: 28,
    lineHeight: 32,
    fontFamily: Fonts.primaryBold,
    color: getClass("text-white"),
    textAlign: "center",
  },
  durationContainer: {
    marginTop: 16,
    marginHorizontal: 16,
    marginBottom: 15,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  locationPin: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
  text: {
    color: getClass("text-white"),
    fontSize: 16,
    lineHeight: 20,
    fontFamily: Fonts.primaryRegular,
  },
  boldText: {
    color: getClass("text-white"),
    fontSize: 14,
    lineHeight: 17,
    fontFamily: Fonts.primaryBold,
  },
  locationText: {},
  tagContainer: {
    height: 20,
    paddingHorizontal: 6,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    backgroundColor: Colors.grayBackground,
  },
  tagText: {
    fontSize: 16,
    lineHeight: 18,
    color: Colors.whiteText,
  },
  eventButtonContainer: {
    borderRadius: 12,
    height: 24,
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    alignItems: "center",
    justifyContent: "center",
    width: 104,
    flexDirection: "row",
  },
  eventButtonText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 18,
    color: getClass("text-white"),
  },
  eventIcon: {
    width: 11,
    height: 11,
    marginRight: 4,
  },
  badgeIcon: {
    marginLeft: 8,
    width: 10,
    height: 10,
  },
  nameContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  username: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 18,
    color: Colors.placeholder,
  },
  icon: {
    width: 19,
    height: 19,
  },
  bio: {
    marginLeft: 16,
    marginTop: 10,
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 20,
    color: Colors.grayText2,
    textAlign: "center",
    position: "absolute",
    width: "100%",
    zIndex: 0,
  },
  bioMobile: {
    marginLeft: 16,
    marginTop: 10,
    fontFamily: Fonts.primaryRegular,
    fontSize: 12,
    lineHeight: 20,
    color: Colors.grayText2,
  },
});

export default VenueInfoView;
